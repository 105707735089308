/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "theme/variables";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* Null styles */

body {
  background: $monitoring-black;
}

ul, li  {
  list-style: none;
  padding: 0;
  margin: 0;
}


.container {
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 15px;
}

ion-modal {
  z-index: 40010!important;
  --width: 100% !important;
  --min-width: auto !important;
  --max-width: auto !important;
  --height: 100% !important;
  --min-height: auto !important;
  --max-height: auto !important;
  --background: var(--ion-background-color, rgba(0, 0, 0, 0.529));
}


/* Loader */

.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.43);
  .loading-spinner {
    position: relative;
    border: 6px solid $monitoring-gray-20; /* установка цвета серого круга */
    border-top: 6px solid $monitoring-red; /* установка цвета синей полоски */
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 1.5s linear infinite;

    &:after {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      content: "";
      background: $monitoring-red;
      display: block;
      position: absolute;
      bottom: 30.1px;
      left: 30.3px;
    }

    &:before {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      content: "";
      background: $monitoring-red;
      display: block;
      position: absolute;
      bottom: 30.1px;
      left: 0.6px;
    }
  }
}

.custom-loading {
  --background: none;

  .loading-wrapper {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.custom-loading.hide-loading {
  .loading-wrapper {
    animation: fade-out 0.5s ease-out forwards;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Auth styles */

.auth-content {
  display: flex;
  background: $monitoring-black;

  .left-side-item {
    background: #FBFBFB;
    min-width: 460px;
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media (max-width: 960px) {
      min-width: 0;
      width: 30%;
    }

    @media (max-width: 680px) {
      display: none;
    }

    &-logo {
      display: block;
      margin: 130px auto 0;
      padding: 0 5px;
      width: 22em;
      transition: 0.3s;
      @media (max-width: 960px) {
        width: 10em;
      }
    }

    .waves {
      img {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

  }

  .auth-container {
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    @media (max-width: 960px) {
      width: 70%;
    }

    @media (max-width: 680px) {
      width: 100%;
    }

    .auth-form {
      display: flex;
      flex-direction: column;
      width: 300px;
      padding: 22px 15px 15px;
      background: $monitoring-white;
      border-radius: 15px;

      &-title {
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: $monitoring-gray-black;
        margin-bottom: 20px;
      }

      &-inputs {
        .input-item {
          margin-bottom: 31px;
          position: relative;
          .passwords-error-message {
            position: absolute;
            left: 0;
            bottom: -17px;
            font-weight: 400;
            font-size: $font-size-small;
            line-height: 14px;
            color: $monitoring-red;
          }
        }
      }

      .routes {
        margin-top: 40px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        font-size: $font-size-medium;

        &-forgot-password {
          color: $monitoring-blue;
          margin-bottom: 20px;
          cursor: pointer;
        }

        &-registration {
          color: $monitoring-gray-40;
          margin-right: 2px;
          span {
            font-size: $font-size-medium;
            color: $monitoring-blue;
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* Page */
.page {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: $monitoring-black;
  padding: 0px 0 50px;
  overflow: auto;
  overflow-y: scroll;
  //&:after {
  //  content: "";
  //  position: fixed;
  //  bottom: 0px;
  //  left: 0;
  //  height: 44px;
  //  width: calc(100% - 20px);
  //  margin: 0 auto;
  //  transform: rotate(180deg);
  //  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
  //  z-index: 2;
  //  pointer-events: none;
  //  @media (max-width: 910px) {
  //    width: 100%;
  //  }
  //}

  &__logo {
    position: fixed;
    left: 60px;
    top: 50px;
    max-width: 198px;
    z-index: 6;
    img {
      height: 100%;
      width: 100%;
    }
    @media (max-width: 910px) {
      display: none;
    }
  }

  &__logout {
    position: fixed;
    right: 60px;
    top: 60px;
    max-width: 24px;
    cursor: pointer;
    z-index: 6;
    opacity: 0.8;
    transition: 0.3s;
    &:hover {
      opacity: 1;
    }
    @media (max-width: 440px) {
      right: 20px;
      top: 20px;
    }
  }

  &__back {
    position: fixed;
    right: 60px;
    top: 50px;
    cursor: pointer;
    z-index: 6;
    opacity: 0.8;
    transition: 0.3s;
    &:hover {
      opacity: 1;
    }
    @media (max-width: 440px) {
      right: 20px;
      top: 12px;
    }
  }

  .page-header {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-top: 50px;
    padding-bottom: 10px;
    background: $monitoring-black;
    z-index: 5;
    &.start-page {
      position: sticky;
      left: 0;
      top: 0;
      width: 100%;
      padding-bottom: 0px;
    }

    &__main {
      z-index: 5;
      background: $monitoring-black;
      color: $monitoring-white;
      width: 100%;
      font-size: 25px;
      display: flex;
      justify-content: center;
      text-align: center;
      min-height: 80px;
      @media (max-width: 900px) {
        min-height: 50px;
      }
      &.start-page {
        &:after {
          display: none;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -42px;
        left: 0;
        height: 44px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        pointer-events: none;
      }
    }

    &__controls {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 50px;
      @media (max-width: 1260px) {
        margin-top: 50px;
        flex-wrap: wrap;
        //justify-content: center;
        //.header-controls__btn-save {
        //  width: 100%;
        //}
      }
      @media (max-width: 780px) {
        margin-top: 20px;
      }

      .versions {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        min-width: 150px;
        .versions-t-block {
          display: flex;
          align-items: flex-start;
          gap: 5px;
        }
        app-select-dropdown {
          .select-with-dropdown {
            height: 46.99px;
          }
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      .header-controls__btn {
        min-width: 125px;
      }
      .header-controls__menu {
        margin-top: auto;
        margin-bottom: auto;
      }
      .filters-and-columns-control {
        display: flex;
        align-items: center;
        gap: 40px;
        @media (max-width: 900px) {
          margin-left: auto;
        }
        .kiosks-count {
          color: $monitoring-white;
        }
      }
      .icon-menu {
        display: block;
        position: relative;
        //top: 70px;
        //right: 15px;
        width: 30px;
        height: 18px;
        cursor: pointer;
        z-index: 5;
        &.icon-menu-filter {
          .filters-count {
            background: darkcyan;
            height: 15px;
            width: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: absolute;
            right: -6px;
            top: -12px;
            z-index: 2;
            font-size: 11px;
            text-align: center;
            color: #fff;
          }
          &.active {
            .icon-menu-filter-span-1{
              transform: rotate(-45deg);
              top: calc(50% - 1px);
            }
            .icon-menu-filter-span-2 {
             transform: scale(0);
            }
            .icon-menu-filter-span-3 {
              transform: rotate(45deg);
              bottom: calc(50% - 1px);
            }
            .icon-menu-filter-round-1,
            .icon-menu-filter-round-2,
            .icon-menu-filter-round-3 {
              transform: scale(0);
            }
          }
          .icon-menu-filter-round-1,
          .icon-menu-filter-round-2,
          .icon-menu-filter-round-3 {
            transition: all 0.3s ease 0s;
            position: absolute;
            background: $monitoring-white;
            height: 8px;
            width: 8px;
            border-radius: 50%;
          }
          .icon-menu-filter-span-1,
          .icon-menu-filter-span-2,
          .icon-menu-filter-span-3 {
            transition: all 0.3s ease 0s;
            // top: calc(50% - 1px);
            left: 0px;
            position: absolute;
            // width: 80%;
            width: 100%;
            height: 2px;
            background-color: $monitoring-white;
          }
          .icon-menu-filter-round-1 {
            left: 4px;
            top: -3px;
           }

          .icon-menu-filter-round-2 {
            left: 18px;
            top: 5px;
          }

          .icon-menu-filter-round-3 {
            left: 10px;
            top: 13px;
          }
          .icon-menu-filter-span-1 {
            left: 0px;
            top: 0;
          }

          .icon-menu-filter-span-2 {
            left: 0px;
            top: 8px;
          }

          .icon-menu-filter-span-3 {
            left: 0px;
            top: auto;
            bottom: 0;
          }
        }
        &.icon-menu-reset-filters {
          opacity: 0.5;
          pointer-events: none;
          &.active {
            opacity: 1;
            pointer-events: all;
          }
        }
        @media (max-width: 900px) {
          // top: 60px;
        }
        span {
          transition: all 0.3s ease 0s;
          // top: calc(50% - 1px);
          left: 0px;
          position: absolute;
          // width: 80%;
          width: 100%;
          height: 2px;
          background-color: $monitoring-white;
          &:nth-child(1) {
            top: 0px;
            // width: 100%;
          }
          &:nth-child(2) {
            top: 8px;
          }
          &:nth-child(3) {
            top: auto;
            bottom: 0px;
            // width: 60%;
          }
        }
        &.active {
          span {
            transform: scale(0);
            &:first-child {
              transform: rotate(-45deg);
              top: calc(50% - 1px);
            }
            &:last-child {
              transform: rotate(45deg);
              bottom: calc(50% - 1px);
            }
          }
        }
      }

      .column-items-block {
        width: 190px;
        background: #FFFFFF;
        border-radius: 10px;
        position: fixed;
        right: 13px;
        top: 177px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 10px;
        opacity: 0;
        transform: translateX(200%);
        transition: 0.3s;
        z-index: 1024;
        @media (max-width: 900px) {
          right: 13px;
          top: 408px;
          max-height: 270px;
          overflow-y: auto;
        }
        &.active {
          opacity: 1;
          transform: translateX(0%);
        }
        .column-item {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

/* Alert Modal */
.alert-modal {
  &.alert-modal-error {
    .alert-message {
      color: $monitoring-red;
    }
  }
  .alert-wrapper {
    border-radius: 15px;
    .alert-head {
      text-align: center;
      font-weight: $font-weight-bold;
    }

    .alert-message {
      text-align: center;
      font-weight: $font-weight-regular;
      box-shadow: inset 0px -1px 0px #E4E4EF;
    }

    .alert-button-group {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      button {
        width: 50%;
        height: 37px;
        background: #121212;
        border-radius: 9px;
        font-weight: 500;
        padding: 2px 5px;
        font-size: 16px;
        line-height: 18px;
        color: #FFFFFF;
        border: 1px solid #121212;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;
        .alert-button-inner {
          justify-content: center;
        }
        @media (max-width: 990px) {
          min-width: auto;
        }

        &:hover {
          border: 1px solid #FFFFFF;
        }
      }
    }
  }
  &.confirm-modal {
    .alert-button-group {
      padding: 0;
      button {
        padding: 10px;
        width: 50%;
        transition: 0.3s;
        &:hover {
          background: $monitoring-blue-10;
        }
      }
    }
  }
}

ion-toast {
  --box-shadow: 0px 4px 15px rgba(125, 125, 125, 0.25);
  --border-radius: 8px;
  --start: auto;
  --end: 40px;
  --min-width: 260px;
  @media (max-width: 900px) {
    --start: 40px;
  }

  // container styles
  &::part(container) {
    background: $monitoring-white;
    border-radius: 8px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      border-radius: 8px 0px 0px 8px;
      height: 100%;
      width: 6px;
    }
  }

  // default
  &.toast-type-default {
    &::part(header) {
      color: $monitoring-blue;
    }
    &::part(container) {
      &:before {
        background: $monitoring-blue;
      }
    }
  }

  // success
  &.toast-type-success {
    &::part(header) {
      color: #4AC248;
    }

    &::part(container) {
      &:before {
        background: #4AC248;
      }
    }
  }

  // danger
  &.toast-type-danger {
    &::part(header) {
      color: $monitoring-red;
    }
    &::part(container) {
      &:before {
        background: $monitoring-red;
      }
    }
  }

  // warning
  &.toast-type-warning {
    &::part(header) {
      color: #FFCA46;
    }
    &::part(container) {
      &:before {
        background: #FFCA46;
      }
    }
  }

  // header
  &::part(header) {
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    padding-left: 10px;
  }

  // message
  &::part(message) {
    padding-top: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    padding-left: 10px;
  }

  // button
  &::part(button) {
    padding-left: 0;
    padding-right: 0;
    width: 10px;
    top: 0px;
    right: 14px;
    height: 10px;
    position: absolute;
    overflow: inherit;
  }
}

.scroll-style {
  &::-webkit-scrollbar {
    height: 15px;
    width: 15px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background: $monitoring-black;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: $monitoring-gray-20;
    border-radius: 10px;
    border: 3px solid $monitoring-black;
  }
}


.scroll-style-slim {
  &::-webkit-scrollbar {
    height: 5px;
    width: 10px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background: $monitoring-black;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: $monitoring-gray-20;
    border-radius: 10px;
    border: 3px solid $monitoring-black;
  }
}

.date-time-inputs {
  .date-time-input {
    position: relative;
    .clear {
      padding: 10px;
      opacity: 0.8;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        opacity: 1;
      }
    }
  }
}
